<template>
  <el-row :gutter="20">
    <el-col
      v-if="myForm.producto.needsClearing || clearing"
      :xl="{span: 12}"
      :lg="{span: 12}"
      :md="{span: 12}"
      :sm="{span: 24}"
      :xs="{span: 24}"
    >
      <el-form-item
        prop="clearing"
        :error="myForm.errors.get('clearing')"
        :label="$t('forms_flujo.check_form.is_in_clearing')"
      >
        <el-radio-group v-model="myForm.formulario1.clearing">
          <el-radio label="1">
            {{ $t('1') }}
          </el-radio>
          <el-radio label="3">
            {{ $t('0') }}
          </el-radio>
          <el-radio label="2">
            {{ $t('dont_know') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-col>
    <el-col
      v-if="myForm.producto.needsComprobanteDomicilio"
      :xl="{span: 12}"
      :lg="{span: 12}"
      :md="{span: 12}"
      :sm="{span: 24}"
      :xs="{span: 24}"
    >
      <el-form-item
        prop="comprobante_domicilio"
        :label="$t('forms_flujo.check_form.comprobante_domicilio')"
        :error="myForm.errors.get('comprobante_domicilio')"
      >
        <el-radio-group v-model="myForm.formulario1.comprobante_domicilio">
          <el-radio label="1">
            {{ $t('1') }}
          </el-radio>
          <el-radio label="0">
            {{ $t('0') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-col>
    <el-col
      v-if="myForm.producto.needsComprobanteIngresos"
      :xl="{span: 12}"
      :lg="{span: 12}"
      :md="{span: 12}"
      :sm="{span: 24}"
      :xs="{span: 24}"
    >
      <el-form-item
        prop="comprobante_ingresos"
        :label="$t('forms_flujo.check_form.comprobante_ingresos')"
        :error="myForm.errors.get('comprobante_ingresos')"
      >
        <el-radio-group v-model="myForm.formulario1.comprobante_ingresos">
          <el-radio label="1">
            {{ $t('1') }}
          </el-radio>
          <el-radio label="0">
            {{ $t('0') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-col>
  </el-row>
</template>

  <script>
  import 'element-ui/lib/theme-chalk/display.css'

  export default {
  name: 'PzCheckForm',
  components: {},
    props: {
        clearing: {type: Boolean, default: false},
    },
  inject: ['myForm'],
  data() {
  return {
  centerDialogVisible: false,
  }
  },
  created: function () {
  },
  mounted() {
  },
  methods: {}
  }
  </script>

