<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form3_persona.SP.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
        class="row justify-content-center"
      >
        <el-col
          :xl="{span: 24}"
          :lg="{span: 24}"
          :md="{span: 24}"
          :sm="{span: 24}"
          :xs="{span: 24}"
          class="row justify-content-center"
        >
          <el-form-item
            prop="is_unnax_face_id_completed"
            :error="errors.get('nombres')"
          >
            <el-input
              ref="is_unnax_face_id_completed"
              v-model="formulario1.is_unnax_face_id_completed"
              :type="'hidden'"
            />
          </el-form-item>
          <pz-unnax-face-id-iframe
            v-if="iframe_url"
            :url="iframe_url"
          />
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzUnnaxFaceIdIframe from "@/commons/forms/Fields/OpenBanking/PzUnnaxFaceIdIframe";
import {getUnnaxFaceIdUrl} from "@/routes/api/views/integracion";
import {isEmpty} from '@/commons/utils/functions';
import {mapGetters} from "vuex";

export default {
  name: 'PzPersonFormEs3',
  components: {PzUnnaxFaceIdIframe, PzFormFlujo},
  mixins: [baseFormFlujo],
  data() {
    let checkUnnaxFaceIdComplete = (rule, value, callback) => {
      if (this.formulario1.is_unnax_face_id_completed === '') {
        return callback(new Error('El proceso UNNAX no ha concluido'));
      }
      return callback();
    };
    return {
      reference: 'formulario1',
      valid: true,
      formulario1: {
        is_unnax_face_id_completed: ''
      },
      iframe_url: '',
      rules_formulario1: {
        is_unnax_face_id_completed: [{validator: checkUnnaxFaceIdComplete, trigger: 'blur'}]
      }
    }
  },
    computed: {
        ...mapGetters(['form_product'])
    },
  mounted() {
    this.getUnnaxFaceIdUrl();
    window.addEventListener("message", (evt) => {
      console.log("event", evt.data);
      if (evt.data === 'unnax:fullid:widget_success:done') {
            this.formulario1.is_unnax_face_id_completed = 'completed'
        }
      if (evt.data === 'unnax:fullid:start_widget:done') {
        this.$store.dispatch('loader/down', {trigger: this.$options.name})
      }
    });
  },
  methods: {
    getUnnaxFaceIdUrl() {
      console.log('options name', this.$options.name)
      this.$store.dispatch('loader/up', {trigger: this.$options.name})
      this.formulario1.is_unnax_face_id_completed = '';
      getUnnaxFaceIdUrl({unnax_timestamp: Date.now(),loan:this.form_product.loan})
        .then((res) => {
          if (res.data.valid && !isEmpty(res.data.ruta)) {
            // console.log('response', res.data)
            let ruta = res.data.ruta;
            this.iframe_url = ruta;
            if (ruta === true)
              this.formulario1.is_unnax_face_id_completed = 'completed'
          } else {
            let message = isEmpty(res.data.ruta) && res.data.valid
              ? this.$t('views.documentacion.vale.already_signed')
              : this.$t('views.documentacion.vale.not_route_obtain');
            this.$store.dispatch('app/setMessage', message);
          }
        }).catch((error) => {
        this.$store.dispatch('app/catchErrors', {_this: this, error: error});
      });
    }
  }
}
</script>

