<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.SP.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <pz-direction
        v-model="formulario1.direccion"
        ask-postal-code
      />

      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="estado_civil_id"
            :label="$t('forms_flujo.form1_persona.SP.civil_state')"
            :error="errors.get('estado_civil_id')"
          >
            <el-select
              ref="estado_civil_id"
              v-model="formulario1.estado_civil_id"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.SP.civil_state')"
            >
              <el-option
                v-for="item in marital_status"
                :key="item.id"
                :label="item.estado"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.SP.civil_state')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="genero"
            :label="$t('forms_flujo.form1_persona.SP.genero')"
            :error="errors.get('genero')"
          >
            <el-select
              ref="genero"
              v-model="formulario1.genero"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.SP.genero')"
            >
              <el-option
                v-for="item in genero"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.SP.genero')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 6}"
          :lg="{span: 6}"
          :md="{span: 6}"
          :sm="{span: 12}"
          :xs="{span: 12}"
        >
          <el-form-item
            prop="tiene_hijos"
            :label="$t('forms_flujo.form1_persona.SP.tiene_hijos')"
            :error="errors.get('tiene_hijos')"
          >
            <el-select
              ref="tiene_hijos"
              v-model="formulario1.tiene_hijos"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.SP.tiene_hijos')"
            >
              <el-option
                v-for="item in arreglo_hijos"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.SP.tiene_hijos')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 6}"
          :lg="{span: 6}"
          :md="{span: 6}"
          :sm="{span: 12}"
          :xs="{span: 12}"
        >
          <el-form-item
            prop="cant_hijos"
            :label="$t('forms_flujo.form1_persona.SP.cant_hijos')"
            :error="errors.get('cant_hijos')"
          >
            <el-input
              ref="cant_hijos"
              v-model.number="formulario1.cant_hijos"
              :disabled="(!formulario1.tiene_hijos)"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.cant_hijos')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.carnet_conducir"
            :label="$t('forms_flujo.form1_persona.SP.tiene_carnet_conducir')"
            :error="errors.get('carnet_conducir')"
          >
            <el-select
              ref="info_extra.carnet_conducir"
              v-model="formulario1.info_extra.carnet_conducir"
            >
              <el-option
                :value="1"
                :label="$t('1')"
              />
              <el-option
                :value="0"
                :label="$t('0')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="pais_nacimiento"
            :label="$t('forms_flujo.form1_persona.SP.pais_nacimiento')"
          >
            <el-select
              ref="pais_nacimiento"
              v-model="formulario1.pais_nacimiento"
              clearable
              filterable
              :placeholder="$t('forms_flujo.form1_persona.SP.pais_nacimiento')"
            >
              <el-option
                v-for="item in paises"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.SP.pais_nacimiento')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ciudad_nacimiento"
            :label="$t('forms_flujo.form1_persona.SP.ciudad_nacimiento')"
            :error="errors.get('ciudad_nacimiento')"
          >
            <el-input
              ref="ciudad_nacimiento"
              v-model.number="formulario1.ciudad_nacimiento"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.ciudad_nacimiento')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="banco_deposito"
            :label="$t('forms_flujo.form1_persona.SP.banco_deposito')"
            :error="errors.get('banco_deposito')"
          >
            <el-select
              ref="banco_deposito"
              v-model="formulario1.banco_deposito"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.SP.banco_deposito')"
              @change="handleChange"
            >
              <el-option
                v-for="item in bancos"
                :key="item.id"
                :label="item.short_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="cuenta_deposito"
            :label="$t('forms_flujo.form1_persona.SP.cuenta_deposito')"
            :error="errors.get('cuenta_deposito')"
          >
            <el-input
              ref="cuenta_deposito"
              v-model="formulario1.cuenta_deposito"
              :type="'text'"
              :disabled="formulario1.banco_deposito === ''"
              :placeholder="$t('forms_flujo.form1_persona.SP.cuenta_deposito')"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        type="flex"
        justify="center"
      >
        <el-col
          :xl="{span: 24}"
          :lg="{span: 24}"
          :md="{span: 24}"
          :sm="{span: 24}"
          :xs="{span: 24}"

        >
          <div>
            <el-col
                    style="text-align: center;"
            >
          <el-form-item
          prop="open_bank_integration"
          :error="errors.get('open_bank_integration')"
          label=" "
          >
            <el-checkbox
                    v-model="formulario1.open_bank_integration"
                :disabled="unnax_checkbox_disabled"
              @change="setUnnaxDialogVisible(true)">
              <div style="white-space: initial;">
                {{  $t('commons.open_banking.label') }}
              </div>
            </el-checkbox>
          </el-form-item>
        </el-col>
        <el-col
                class="pz-large-margin"
        >
          <div >
            {{ $t('commons.open_banking.label_adicional') }}
          </div>
        </el-col>
        <el-col :span="24">
          <el-dialog
                  class="pz-dialog-banking"
                  :title="$t('commons.bank_aggregation.title_header')|capitalize({ onlyFirstLetter: true })"
                  :visible.sync="show_unnax_dialog"
                  width="50%"
                  center
          >
            <el-row class="row justify-content-center">
              <pz-unnax-iframe
                      v-if="formulario1.widget_url"
                      :url="formulario1.widget_url"
              />
            </el-row>
          </el-dialog>
        </el-col>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {getBancos, getEstadoCivil, getPaises} from "@/routes/api/resources";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import {mapGetters} from "vuex";
import {required} from "@/commons/utils/Rules";
import PzUnnaxIframe from "@/commons/forms/Fields/OpenBanking/PzUnnaxIframe";
import {getBankIntegrationWidgetUrl} from "@/routes/api/views/integracion";
import {isEmpty} from "@/commons/utils/functions";

export default {
  name: 'PzPersonFormEs2',
  components: {PzDirection, PzFormFlujo, PzUnnaxIframe},
  mixins: [baseFormFlujo],
  data() {
      let checkUnnaxComplete = (rule, value, callback) => {
          console.log('checkUnnaxComplete');
          if (this.formulario1.is_unnax_completed === '') {
              return callback(new Error('El proceso UNNAX no ha concluido'));
          }
          return callback();
      };
    var check_hijos = (rule, value, callback) => {
      if (!(this.formulario1.tiene_hijos)) {
        this.formulario1.cant_hijos = '';
        return callback();
      } else {
        return callback();
      }
    };
    var check_doc = (rule, value, callback) => {
      if (!this.regla)
        return callback();
      let pattern = new RegExp(this.regla.toString());
      if (pattern.test(value)) {
        return callback();
      }
      return callback(new Error(this.$t('rules.bank_rule')));

    };
      var checkUnnaxRequired = (rule, value, callback) => {
          if ( this.formulario1.open_bank_integration == '' &&  this.product.bank_integration==1) {
              return callback(new Error('El campo es requerido,por favor verifíquelo.'));
          }else  if ( this.formulario1.is_unnax_completed == '' &&  this.product.bank_integration==1) {
              return callback(new Error('El proceso UNNAX no ha concluido'));
          }
          return callback();
      };

    return {
      reference: 'formulario1',
      genero: [
        {id: 'f', name: this.$t('forms_flujo.form1_persona.SP.femenino')},
        {id: 'm', name: this.$t('forms_flujo.form1_persona.SP.masculino')},
        {id: 'o', name: this.$t('forms_flujo.form1_persona.SP.otro')}
      ],
      studies: [],
      bancos: [],
      marital_status: [],
      arreglo_hijos: [
        {id: 1, name: 'SI'},
        {id: 0, name: 'NO'},
      ],
      vnz: false,
      regla: '',
      paises: [],
        show_unnax_dialog: false,
      formulario1: {
        direccion: {},
        estado_civil_id: '',
        genero: '',
        ciudad_nacimiento: '',
        banco_deposito: '',
        cuenta_deposito: '',
        instantor: {},
        info_extra: {
          carnet_conducir: '',
        },
        numero_documento: '',
        tiene_hijos: '',
        cant_hijos: '',
        pais_nacimiento: '',
        is_unnax_completed: '',
        not_route_obtained: false,
        unnax_timestamp: Date.now(),
        open_bank_integration: '',
        widget_url: '',
      },
      rules_formulario1: {
        genero: [required('')],
        tiene_hijos: [required(''), {validator: check_hijos, trigger: 'change'}],
        cuenta_deposito: [{validator: check_doc, trigger: 'blur'}],
        pais_nacimiento: [required('')],
         open_bank_integration: [{validator: checkUnnaxRequired, trigger: 'blur'}],
          is_unnax_completed: [{validator: checkUnnaxComplete, trigger: 'blur'}],

      },

    };
  },
  computed: {
      unnax_checkbox_disabled() {
          return this.formulario1.is_unnax_completed === 'completed' || this.formulario1.not_route_obtained
      },
    ...mapGetters(['applicant','product'])
  },
  created() {
    this.fetchData();
    this.$root.$on('set_unnax_timestamp', data => this.updateUnnaxTimestamp(data))
  },
  mounted() {
    this.formulario1.numero_documento = this.applicant.numero_documento;
      this.initialize();
      // activa la escucha de mensajes provenientes del iframe
      window.addEventListener("message", (evt) => {
          if (evt.data === 'unnax:aggregation:done') {
              this.formulario1.is_unnax_completed = 'completed';
              this.formulario1.open_bank_integration = true;
              setTimeout(() => this.closeDialog(), 1000)
          }
          if (evt.data === 'unnax:aggregation:cancel:done' ) {
              this.formulario1.is_unnax_completed = '';
              this.formulario1.open_bank_integration = '';
              this.formulario1.unnax_timestamp= Date.now();
              this.formulario1.widget_url = '';
              setTimeout(() => this.closeDialog(), 1000)
          }
          if ( evt.data === 'unnax:widget_start:done') {
              this.formulario1.is_unnax_completed = '';
              this.formulario1.open_bank_integration = '';
          }
          if (evt.data === 'unnax:aggregation:bank_selection_view:done') {
              this.$store.dispatch('loader/down', {trigger: this.$options.name});
          }
      });
  },
  methods: {
    handleChange() {
      const key = this.formulario1.banco_deposito;
      if (this.bancos.length > 0 && key) {
        this.regla = this.bancos.find(datos => datos.id == key);
        this.regla = this.regla.rules;
      }
    },
    fetchData() {
      this.$store.dispatch('loader/up', {trigger: this.$options.name})
      let estado = getEstadoCivil().then((res) => {
        this.marital_status = res.data;
      }).catch(() => {
        this.marital_status = [];
      });
      let bancos = getBancos().then((res) => {
        this.bancos = res.data;
      }).catch(() => {
        this.bancos = [];
      });
      let paises = getPaises().then((res) => {
        this.paises = res.data;
      }).catch(() => {
        this.paises = [];
      });
      return Promise.all([estado, bancos, paises]).then(() => {
      }).finally(() => {
        this.$store.dispatch('loader/down', {trigger: this.$options.name})
      });
    },
    updateUnnaxTimestamp(timestamp) {
      this.formulario1.unnax_timestamp = timestamp
    },
      initialize() {
          this.formulario1.is_unnax_completed = '';
          this.formulario1.not_route_obtained = false;
          this.formulario1.unnax_timestamp= Date.now();
           this.formulario1.open_bank_integration= '';
      },
      // cierra el modal de UNNAX
      closeDialog() {
          this.setUnnaxDialogVisible(false);
      },
      // cambia la visibilidad del modal de UNNAX
      setUnnaxDialogVisible(val) {
          this.show_unnax_dialog = val;
          if (val) {
              this.getUnnaxWidgetUrl()
          }
      },
      getUnnaxWidgetUrl() {
          this.$store.dispatch('loader/up', {trigger: this.$options.name})
          getBankIntegrationWidgetUrl({unnax_timestamp: this.formulario1.unnax_timestamp})
              .then(res => {
                  if (res.data.valid && !isEmpty(res.data.ruta)) {
                      let ruta = res.data.ruta;
                      this.formulario1.widget_url = ruta;
                      if (ruta === true)
                          this.formulario1.is_unnax_completed = 'completed';
                      this.$root.$emit('set_unnax_timestamp', this.formulario1.unnax_timestamp)
                  } else {
                      let message = isEmpty(res.data.ruta) && res.data.valid
                          ? this.$t('views.documentacion.vale.already_signed')
                          : this.$t('views.documentacion.vale.not_route_obtain');
                      if (!res.data.valid) {
                          this.formulario1.not_route_obtained = true;
                          this.formulario1.open_bank_integration = false;
                          this.closeDialog();
                      }
                      this.$store.dispatch('app/setMessage', message);
                  }
              })
              .catch((error) => {
                  this.$store.dispatch('app/catchErrors', {_this: this, error: error});
              })
              .finally(() => {
                  this.$store.dispatch('loader/down', {trigger: this.$options.name});
              });
      }
  },
}
</script>

